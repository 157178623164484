<template>
  <div :class="customClass">
    <img :src="data.image_url" />
    <div class="bundle-name">{{ data.name }}</div>
    <div class="bundle-price">
      <template v-if="delivery">
        ฿{{ data.price_ship | currency }}.-
      </template>
      <template v-else>
        ฿{{ data.price_store | currency }}.-
      </template>
    </div>
    <div class="bundle-button">
      <template v-if="data.type !== 'free'">
        <Button
          v-if="!select"
          @clickStop="$emit('click')"
          :disabled="!data.active"
          block
          depressed
          color="color-bnn"
          height="0px"
          :text-small="forceBuyBundle"
          :title="textButton(data.active, labelButton)"
        />
        <Button
          v-else
          @clickStop="$emit('click')"
          :disabled="!data.active"
          block
          depressed
          color="color-bnn-gray-light-3"
          height="0px"
          :text-small="forceBuyBundle"
          :title="textButton(data.active, 'ใส่ตะกร้าแล้ว')"
        />
      </template>
      <template v-else>
        <Button
          disabled
          block
          depressed
          color="color-bnn-gray-light-2"
          height="0px"
          :text-small="forceBuyBundle"
          :title="textButton(data.active, 'สินค้าแถม')"
        />
      </template>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Button from '@/components/bnn/Button'
export default {
  computed: {
    ...mapState({
      delivery: state => state.cart.delivery,
      selectSKU: state => state.model.selectSKU,
    }),

    customClass() {
      // if (this.select) {
      //   return 'bundle-card select'
      // }
      return 'bundle-card'
    },

    forceBuyBundle() {
      return this.selectSKU?.force_bundle === 1
    },

    labelButton() {
      return !this.select && this.isFreebiesByCoupon
        ? 'เลือก'
        : 'หยิบใส่ตะกร้า'
    },
  },

  components: {
    Button,
  },

  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    select: {
      type: Boolean,
      default: false,
    },
    isFreebiesByCoupon: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    textButton(active, defaultText) {
      if (this.forceBuyBundle) {
        return 'สินค้านี้อยู่ในแพ็กเกจ'
      } else if (active) {
        return defaultText
      } else {
        return 'สินค้าหมด'
      }
    },
  },
}
</script>

<style lang="stylus" scoped>

.bundle-card
    position relative
    // border: 1px solid $color-bnn-gray-light
    background $color-bnn-gray-light-2 0% 0% no-repeat padding-box
    border-radius: 8px;
    display: flex
    justify-content: center
    flex-wrap: wrap
    gap: 16px 0;
    // grid-template-columns: repeat(1, 1fr);
    height: 245px;
    padding 1rem 0.05rem
    &:hover
        cursor pointer
    img {
        max-width:100px;
        max-height:100px;
        width: 100%
        height: 100%
        object-fit: contain
    }
    div {
        font-size: 0.75rem
        width 100%
    }
	.bundle-button
		padding: 8px

.bundle-price {
    font-size: 0.9rem
    font-weight: bold
    color: $color-text-price
    margin-top: -1rem
    padding-left: 1rem
    text-align: left
    bottom: 0
    left: 0
}

.bundle-name {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 2.35rem;
    text-align: left
    padding: 0 1rem;
}

.bundle-card.select
    border: 1px solid $color-bnn-gray-medium;

.freebies-by-coupon
	.bundle-card
		max-width: 100%
		height: fit-content
		padding 1rem
		gap: 16px
		&:hover
			cursor pointer
		img {
			max-width: 100%
			max-height: 100%
			width: 100%
			height: 100%
			object-fit: contain
		}
		.bundle-price
			height: fit-content
			font-size: 16px
			font-weight: bold
			line-height: 1
			padding: 0
		.bundle-name
			height: 28px
			font-size: 14px
			font-weight: normal
			line-height: 1
			padding: 0
		.bundle-button
			padding: 0
</style>
