<template>
  <div>
    <swiper
      ref="swiper"
      :class="[
        'swiper swiper-bundle',
        { 'freebies-by-coupon': isFreebiesByCoupon },
      ]"
      :options="swiperOption"
    >
      <swiper-slide v-for="(item, index) in data" :key="index + 1">
        <BundleCard
          @click="$emit('click', item)"
          :select="isSelected(item)"
          :data="item"
          is-freebies-by-coupon
        />
      </swiper-slide>

      <div class="swiper-button-prev" slot="button-prev">
        <span class="angle-icon">
          <font-awesome-icon size="lg" icon="angle-left" />
        </span>
      </div>
      <div class="swiper-button-next" slot="button-next">
        <span class="angle-icon">
          <font-awesome-icon size="lg" icon="angle-right" />
        </span>
      </div>
    </swiper>
  </div>
</template>

<script>
import BundleCard from '@/components/bnn/BundleCard'

export default {
  name: 'swiper-example-loop-group',
  title: 'Loop mode with multiple slides per group',

  components: {
    BundleCard,
  },

  computed: {
    swiper() {
      return this.$refs.swiper.swiper
    },
  },

  props: {
    data: {
      type: Array,
      default: () => [],
    },
    isFreebiesByCoupon: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      swiperOption: {
        preventClicksPropagation: false,
        spaceBetween: 16,
        slidesPerView: 2,
        slidesPerGroup: 2,
        pagination: {
          el: '.swiper-pagination-bundle',
          clickable: false,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        breakpoints: {
          // when window width is >= 320px
          320: {
            slidesPerView: 1,
            slidesPerGroup: 1,
          },
          // when window width is >= 640px
          640: {
            slidesPerView: 3,
            slidesPerGroup: 3,
          },
          // when window width is >= 640px
          1280: {
            slidesPerView: 3,
            slidesPerGroup: 3,
          },
        },
      },
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.$refs.swiper.$swiper.update()
    })
  },

  methods: {
    unit(item) {
      return this.$store.getters['bundle/countBySKU'](item)
    },

    isSelected(item) {
      return this.unit(item) > 0
    },
  },
}
</script>

<style lang="stylus" scoped>

.swiper-bundle {
    padding-left: 1.8rem
    padding-right: 1.8rem
    background white
}

.angle-icon {
    width: 28px
    height: 28px
    background: $color-bnn-gray-medium-2
    border-radius: 50px
    display: flex
    justify-content: center
    align-items: center
}

.swiper {
  height: 350px;
  width: 100%;

  &.freebies-by-coupon {
	height: fit-content;
	.swiper-slide {
		display: unset
		position: relative
	}
  }

  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: bold;
    font-size: 1rem;
    background: none
  }
}

.swiper-button-prev, .swiper-rtl .swiper-button-next {
  left 0 !important
  position : absolute
}

.swiper-button-next, .swiper-rtl .swiper-button-prev {
  right 0 !important
}

.swiper-button-next::after, .swiper-button-prev::after {
    content: "" !important
}

.swiper-button-next, .swiper-button-prev {
    color: white !important
    width auto !important
}

.swiper-button-prev,
.swiper-button-next
	transition: 0.2s ease-in-out !important
.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled
	transition: 0.2s ease-in-out !important
</style>
